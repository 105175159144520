import React, { useState } from 'react'
import { client } from './../Client'
import Loader from '../../helpers/Loader'
import { Button } from 'react-bootstrap'
import { toastrNotification } from '../../helpers/Toastr'

const emptyContact = { value: '',
                       type: 'email',
                       error: '',
                       success_message: '',
                       sending: false,
                       sent: false }

export default function Invitations({ course }) {
  const [submitting, setSubmitting] = useState(false)
  const [completed, setCompleted] = useState(false)
  const [contacts, setContacts] = useState([emptyContact])

  const reset = () => {
    setContacts([])
    setCompleted(false)
  }

  const submit = () => {
    setSubmitting(true)
    setContactsSending()

    let newContacts = [...contacts]
    let counter = 0

    contacts.forEach((contact, index) => {
      const invitationData = {
        course_id: course.id,
        role: "customer"
      }

      invitationData['phone'] = contact.value
      invitationData['email'] = contact.value

      client.createInvitation(invitationData)
      .then(response => {
        counter = counter + 1

        let error = '', success_message = ''
        if (!response.success) {
          error = response.error ? response.error : window.t('invitation.unable_create_invite')
        } else {
          success_message = response.message || ""
        }

        newContacts[index].sending = false
        newContacts[index].sent = true
        newContacts[index].error = error
        newContacts[index].success_message = success_message

        if (counter >= contacts.length) {
          setSubmitting(false)
          setContacts(newContacts)
          setCompleted(true)
        }
      })
    })
  }

  const handleContactChange = (index, ev) => {
    let newContacts = contacts.map((contact, cIndex) => {
      const newContact = index === cIndex ? {...contact, value: ev.target.value} : contact
      return newContact
    })
    setContacts(newContacts)
  }

  const setContactsSending = () => {
    const newContacts = contacts.map(contact => {
      const newContact = {...contact, sending: true}
      return newContact
    })
    setContacts(newContacts)
  }

  const addContactInput = (e, contactType) => {
    e.preventDefault()

    const newContact = {...emptyContact, type: contactType}

    setContacts([...contacts, newContact])
  }

  const deleteContactInput = (index) => {
    let newContacts = [...contacts]
    newContacts.splice(index, 1)
    setContacts(newContacts)
  }

  const renderContactButtonIcon = (contact) => {
    if (contact.sent) {
      if (contact.error.length > 0) {
        return (<i className="fas fa-exclamation text-danger"></i>)
      } else {
        return (<i className="fas fa-check text-success"></i>)
      }
    } else {
      if (contact.sending) {
        return (
          <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        )
      } else {
        return (<i className="fas fa-times"></i>)
      }
    }
  }

  return (
    <>
      <div className="card-body mt-2 mt-sm-2 pt-0">
        <div className="alert alert-secondary" role="alert">
           { window.t("invitation.help_text") }
        </div>
        { contacts.map((contact, index) => (
            <div key={'contact_'+index} className="mb-2">
              <div className="d-flex">
                { contact.type === 'phone'
                  ?
                  <input
                    type="tel"
                    name="contacts[]"
                    id={'contact' + index}
                    className={"form-control " + (contact.sent && contact.error.length > 0 ? 'is-invalid' : '') }
                    autoComplete="off"
                    value={contact.value}
                    placeholder="+79123456789"
                    onChange={ev => handleContactChange(index, ev)}
                  />
                  :
                  <input
                    type="email"
                    name="contacts[]"
                    id={'contact' + index}
                    className={"form-control " + (contact.sent && contact.error.length > 0 ? 'is-invalid' : '') }
                    autoComplete="off"
                    value={contact.value}
                    placeholder="user@example.com или +7xxxxxxxxxx"
                    onChange={ev => handleContactChange(index, ev)}
                  />
                }
                <button disabled={contact.sending || contact.sent} className="btn btn-default" onClick={() => deleteContactInput(index)}>
                  { renderContactButtonIcon(contact) }
                </button>
              </div>
              { contact.sent && contact.error.length > 0 &&
                <div className="invalid-feedback d-block mt-2">
                  { contact.error }
                </div>
              }
              { contact.sent && contact.success_message.length > 0 &&
                <div className="tx-12 text-success d-block mt-2">
                  { contact.success_message }
                </div>
              }
            </div>
          ))
        }

        <div className="row">
          <div className="col-12 mt-3">
            {/* <button onClick={ev => addContactInput(ev, 'phone')} disabled={completed || submitting} className="btn btn-outline-primary btn-sm me-2">{ window.t('invitation.add_phone_or_email') }</button> */}
            <button onClick={ev => addContactInput(ev, 'email')} disabled={completed || submitting} className="btn btn-outline-primary btn-sm">{ window.t('invitation.add_phone_or_email') }</button>
          </div>
        </div>
      </div>
      <div className="card-footer">
        <div className="d-flex justify-content-end">
          { completed
            ?
            <Button className="btn btn-secondary" onClick={() => reset()}>{ window.t('invitation.reset') }</Button>
            :
            <Button className="btn btn-primary" disabled={submitting || contacts.length === 0} onClick={() => submit()}>
              { submitting &&
                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
              }
              &#160;{ window.t('invitation.invite') }
            </Button>
          }
        </div>
      </div>
    </>
  )
}
