import React from 'react'
import { Card, Button, Dropdown, DropdownButton } from 'react-bootstrap'
import Swal from 'sweetalert2'
import { client } from '../../Client'
import CourseLection from './CourseLection'
import FilesUploader from './FilesUploader'
import { handleRemoveLinkClick } from '../../../helpers/RemoveLinksClickHandler'

class CourseSection extends React.Component {

  render() {
    const { section, course, locale, myIndex } = this.props

    const sectionButtons = (
      <>
        <a href={Routes.edit_tutor_course_section_path(course.slug, section.id)}
          className="btn btn-sm btn-primary-soft btn-round ms-md-3 mb-0"
        >
          <i className="far fa-fw fa-edit"></i>
        </a>
        <a href={Routes.tutor_course_section_path(course.slug, section.id)}
          id={`remove-${section.id}`}
          className="btn btn-sm btn-danger-soft btn-round ms-2 mb-0"
          onClick={event => handleRemoveLinkClick(event)}
          data-confirm-target="modal"
          data-confirm-title={window.t("course.section_destroy_confirm_title")}
          data-confirm-text={window.t("course.section_destroy_confirm_text")}
        >
          <i className="fas fa-fw fa-times"> </i>
        </a>
        {myIndex !== 0 &&
          <button className="btn btn-sm btn-dark-soft btn-round ms-2 mb-0" onClick={e => this.props.onSortEnd(myIndex, myIndex - 1)} >
            <i className="fas fa-fw fa-chevron-up"></i>
          </button>
        }
        {myIndex !== course.sections.length - 1 &&
          <button className="btn btn-sm btn-dark-soft btn-round ms-2 mb-0" onClick={e => this.props.onSortEnd(myIndex, myIndex + 1)} >
            <i className="fas fa-fw fa-chevron-down"></i>
          </button>
        }
      </>
    )

    return (
      <>
        <div className="w-100 course-section fw-bold rounded" id={`heading-${section.id}`}>
          <div className="d-flex flex-column flex-md-row justify-content-center align-items-md-center">
            <h6 className="mb-0">{section.title}</h6>
            <div className="mt-2 mt-md-0">{sectionButtons}</div>
          </div>
          <button className="btn mb-0 collapse-btn" type="button" data-bs-toggle="collapse" data-bs-target={`#collapse-${section.id}`} aria-expanded={(myIndex == 0 ? "true" : "false")} aria-controls={`collapse-${section.id}`}>
          </button>
        </div>

        <div id={`collapse-${section.id}`} className={"accordion-collapse collapse " + (myIndex == 0 ? "show" : "")} aria-labelledby={`heading-${section.id}`} data-bs-parent="#courseSectionsAccordion" >
          <div className="accordion-body mt-3">
            {section.lections.map((lection, index) => (
              <CourseLection
                key={`lection-${lection.id}`}
                lection={lection}
                course={course}
                section={section}
                onUpdateLection={this.props.onUpdateLection}
                onDeleteLection={this.props.onDeleteLection}
                onLectionsSortEnd={this.props.onLectionsSortEnd}
                myIndex={index}
                locale={locale}
              />
            ))}
          </div>

          <div className="dropup ms-3">
            <button className="btn btn-sm btn-dark mb-0 dropdown-toggle" type="button" id="dropdownSectionMenu" data-bs-toggle="dropdown" aria-expanded="false">
              <i className="bi bi-plus-circle me-2"></i>
              {window.t('course.new_lection')}
            </button>
            <ul className="dropdown-menu" aria-labelledby="dropdownSectionMenuButton">
              <li><a className="dropdown-item" href={Routes.new_tutor_course_lection_path(locale, course.slug, { section_id: section.id })}>
                <i className="fas fa-align-left"></i> {window.t('course.add_text')}</a>
              </li>
              <li><a className="dropdown-item" href={Routes.new_tutor_course_survey_path(locale, course.slug, { section_id: section.id })}>
                <i className="fas fa-poll"></i> {window.t('course.add_survey')}</a>
              </li>
              <li><a className="dropdown-item" href={Routes.new_tutor_course_stop_block_path(locale, course.slug, { section_id: section.id })}>
                <i className="fas fa-ban"></i> {window.t('course.add_stop_block')}</a>
              </li>
              <FilesUploader course={course} section={section} />
            </ul>
          </div>
        </div>
      </>
    )
  }

}

export default CourseSection