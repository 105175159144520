import React from 'react'
import { Card, Button, Dropdown, Badge } from 'react-bootstrap'
import Swal from 'sweetalert2'
import { client } from '../../Client'
import { handleRemoveLinkClick } from '../../../helpers/RemoveLinksClickHandler'
import { toastrNotification } from '../../../helpers/Toastr'


class CourseLection extends React.Component {

  renderLectionIcon = () => {
    const lection = this.props.lection
    if (lection.content.is_video) {
      return (<i className="far fa-play-circle"></i>)
    } else if (lection.content.is_survey) {
      return (<i className="fas fa-poll"></i>)
    } else if (lection.content.is_stop_block) {
      return (<i className="fas fa-ban"></i>)
    } else if (lection.content.is_recording) {
      return (<i className="fas fa-video"></i>)
    } else if (lection.content.is_homework) {
      return (<i className="far fa-file-alt"></i>)
    } else if (lection.content.is_text) {
      return (<i className="fas fa-align-left"></i>)
    } else {
      return (<i className="far fa-file"></i>)
    }
  }

  renderStatusBadge = () => {
    const lection = this.props.lection
    var badgeStyle, message
    if (lection.content.is_video) {
      switch (lection.content.status) {
        case 'transcoding':
          badgeStyle = { backgroundColor: "rgba(var(--bs-primary-rgb), var(--bs-bg-opacity))" }
          message = window.t('course.video_transcoding_short')
          break
        case 'ready':
          if (lection.content.subtitled) {
            badgeStyle = { backgroundColor: "rgba(var(--bs-success-rgb), var(--bs-bg-opacity))" }
            message = window.t('course.video_ready')
          }
          else {
            badgeStyle = { backgroundColor: "rgba(var(--bs-primary-rgb), var(--bs-bg-opacity))" }
            message = window.t('course.subtitling')
          }
          break
        case 'uploaded':
        case 'uploaded':
          badgeStyle = { backgroundColor: "rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity))" }
          message = window.t('course.video_uploaded')
          break
        case 'failed':
        case 'failed':
          badgeStyle = { backgroundColor: "rgba(var(--bs-danger-rgb), var(--bs-bg-opacity))" }
          message = window.t('course.video_failed')
          break
      }
      return (
        <span className="badge badge-pill badge-success mx-1" style={badgeStyle}>
          {message}
        </span>
      )
    }
  }

  showAiHelpForUnsupportedFormat = () => {
    const lection = this.props.lection
    var modalAi = new bootstrap.Modal(document.getElementById('aiModal'));
    document.getElementById('modalTitle').innerHTML = window.t('lection.ai_help_title')
    document.getElementById('modalBody').value = window.t('ai.generate_request')
    modalAi.toggle()
    var prompt = window.t('ai.request.convert_unsupported_format_to_pdf') + `*.${client.extractFileExt(lection.content.name)}`
    client.getAiHelp(prompt)
      .then(result => {
        if (result.success == true) {
          toastrNotification({ type: 'success', title: window.t('ai.success') })
          document.getElementById('modalBody').value = result.message
        }
        else {
          toastrNotification({ type: 'error', title: window.t('ai.error') })
          document.getElementById('modalBody').value = ""
        }
      })

  }

  renderUnsupportFileFormatBadge = () => {
    const lection = this.props.lection
    var badgeStyle = { backgroundColor: "rgba(var(--bs-danger-rgb), var(--bs-bg-opacity))" }
    if (lection.content.name && client.checkReactViewerExt(lection.content.name) == false) {
      return (
        <span className='ps-1 text-muted h6 fw-light mb-0 small'
          title={window.t('lection.warning_text')}
          onClick={this.showAiHelpForUnsupportedFormat}
        >
          <span className="badge badge-pill badge-success mx-1" style={badgeStyle}>
            {window.t('lection.warning')}
          </span>
        </span>
      )
    }
  }

  editLink = () => {
    const { lection, course } = this.props
    if (lection.content.is_survey) {
      return Routes.edit_tutor_course_survey_path(locale, course.slug, lection.content.id)
    } else if (lection.content.is_homework) {
      return Routes.edit_tutor_course_homework_path(locale, course.slug, lection.content.id)
    } else if (lection.content.is_stop_block) {
      return Routes.edit_tutor_course_stop_block_path(locale, course.slug, lection.content.id)
    } else {
      return Routes.edit_tutor_course_lection_path(locale, course.slug, lection.id)
    }
  }

  generateQuestions = () => {
    const { lection, course, section, myIndex } = this.props
    toastrNotification({ type: 'success', title: window.t('ai.survey_creating') })
    client.generateQuestionsForLection(course.id, lection.id)
      .then(result => {
        if (result.status == "success") {
          toastrNotification({ type: 'success', title: result.message })
          location.reload()
        }
        else {
          toastrNotification({ type: 'error', title: result.message })
        }
      })
  }

  render() {
    const { lection, course, section, locale, myIndex } = this.props

    return (
      <>
        <div className="d-block d-sm-flex justify-content-between align-items-center">
          <div className="d-flex align-items-center">
            <div className="position-relative">
              <a href={this.editLink()} className="btn btn-danger-soft btn-round btn-sm mb-0 stretched-link position-static">
                {this.renderLectionIcon()}
              </a>
              <span className="ms-2 mb-0 h6 fw-light ">
                {lection.title}
                {lection.content.is_survey &&
                  <span className="ps-1 text-muted h6 fw-light mb-0 small me-3">{lection.content.questions_total_s}</span>
                }
                {lection.content.is_stop_block &&
                  <span className="ps-1 text-warning h6 fw-light mb-0 small me-3">{lection.content.notification}</span>
                }
                {lection.content.is_video &&
                  <span className="ps-1 text-muted h6 fw-light mb-0 small me-3">
                    {this.renderStatusBadge()}
                    {lection.content.duration}
                  </span>
                }
              </span>
            </div>
            {!lection.content.is_video && !lection.content.is_survey && !lection.content.is_stop_block && this.renderUnsupportFileFormatBadge()}
          </div>

          <div className="mt-3 mt-sm-0">
            {!(lection.content.is_survey || lection.content.is_document || lection.content.is_stop_block)
              && (lection.content.subtitled || !lection.content.is_video) &&
              < a href="#"
                title={window.t("course.generate_survey_by_ai")}
                onClick={this.generateQuestions}
                className="btn btn-sm btn-primary-soft btn-round mb-0"
              >
                <i className="fas fa-tasks"></i>
              </a>
            }

            <a href={this.editLink()}
              title={window.t("course.edit")}
              className="btn btn-sm btn-primary-soft btn-round ms-2 mb-0"
              id={`edit-${lection.id}`}
            >
              <i className="far fa-fw fa-edit"></i>
            </a>

            <a href={Routes.tutor_course_lection_path(course.slug, lection.id)}
              title={window.t("course.delete")}
              id={`remove-${lection.id}`}
              className="btn btn-sm btn-danger-soft btn-round ms-2 mb-0"
              onClick={event => handleRemoveLinkClick(event)}
              data-confirm-target="modal"
              data-confirm-title={window.t("course.lection_destroy_confirm_title")}
              data-confirm-text={window.t("course.lection_destroy_confirm_text")}
            >
              <i className="fas fa-fw fa-times"></i>
            </a>
            {myIndex !== 0 &&
              <button className="btn btn-sm btn-dark-soft btn-round ms-2 mb-0" onClick={e => this.props.onLectionsSortEnd(section, myIndex, myIndex - 1)} >
                <i className="fas fa-fw fa-chevron-up"></i>
              </button>
            }
            {myIndex !== section.lections.length - 1 &&
              <button className="btn btn-sm btn-dark-soft btn-round ms-2 mb-0" onClick={e => this.props.onLectionsSortEnd(section, myIndex, myIndex + 1)} >
                <i className="fas fa-fw fa-chevron-down"></i>
              </button>
            }
          </div>
        </div >
        <hr />
      </>
    )
  }

}

export default CourseLection