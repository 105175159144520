import React from 'react'
import { client } from '../Client'
import CourseSection from './CourseSection'
import VideoPlayer from './VideoPlayer'
import DeleteConfirmationModal from './DeleteConfirmationModal'
import ContentDocument from './ContentDocument'
import ContentSurvey from './ContentSurvey'
import ContentRecording from './ContentRecording'
import ContentText from './ContentText'
import ContentHomework from './ContentHomework'
import ContentEmpty from './ContentEmpty'
import ContentError from './ContentError'
import FinishCourseMessage from './FinishCourseMessage'
import NoteModal from './NoteModal'
import ConversationMobile from '../ConversationMobile/ConversationMobile'


import './lecture-content.scss'

class CourseViewerMobile extends React.Component {

  constructor(props) {


    super(props)
    const lectionId = this.props.match.params.lectionId,
      lection = this.props.course.lections.find(l => l.id == lectionId)

    this.state = {
      conversation: props.conversation,
      course: props.course,
      trackers: props.trackers,
      notes: props.notes,
      currentLection: lection,
      videoStartTime: 0,
      progress: this.calculateProgress(props.trackers, props.course.lections),
      finishCourseMessageIsActive: false
    }

    this.videoPlayerRef = React.createRef(null)
    this.showMenu = this.showMenu.bind(this);
  }

  componentDidMount() {
    const { course, license } = this.props;

    if (license.need_confirmation) {
      this.intervalID = setInterval(() => {
        client.updateCourseLicenseViewTime(course.id, license.id)
          .then(response => {
            if (response.redirect_url) window.location = response.redirect_url
          })
      }, 60000)
    }
  }

  componentWillUnmount() {
    if (this.intervalId) clearInterval(this.intervalID)
  }

  calculateProgress(trackers, lections) {
    if (lections.length == 0) { return 0 }
    if (this.props.preview) { return 0 }
    return Math.round(trackers.filter(tr => tr.completed_at !== null).length / lections.length * 100)
  }

  updateCourse = (updatedCourse) => {
    this.setState({ course: updatedCourse })
  }

  addNote = (newNote) => {
    this.setState({ notes: [...this.state.notes, newNote] })
  }

  deleteNote = (deletedNote) => {
    const license = this.props.license
    client.deleteCourseNote(license.id, deletedNote.id)
      .then(response => {
        const newNotes = this.state.notes.filter(note => note.id !== deletedNote.id)
        this.setState({ notes: newNotes })
      })
  }

  showDeleteNoteConfirmation = (event, deletedNote) => {
    var self = this
    event.preventDefault()
    $('body').addClass('shadow')
    document.getElementById("delete").onclick = function (event) {
      event.preventDefault()
      self.deleteNote(deletedNote)
      $('body').removeClass('shadow')
    }
  }

  loadLection = (event, lection, time = 0, survey_lection = null) => {

    event.preventDefault()

    if (lection.content.is_stop_block) {
      if (survey_lection == null) return
      else lection = survey_lection
    }

    if (lection.content.status == 'transcoding') return

    const { course, trackers } = this.state,
      locale = this.props.match.params.locale,
      license = this.props.license

    let urlParams = { locale: locale }

    if (!this.props.preview) urlParams['license_id'] = license.id
    if (time > 0) urlParams['t'] = time

    const url = this.props.preview ?
      Routes.tutor_course_lection_path(course.slug, lection.id, urlParams) :
      Routes.pupil_course_lection_path(course.slug, lection.id, urlParams)

    this.setState({
      currentLection: lection,
      finishCourseMessageIsActive: false,
      videoStartTime: time
    }, () => {

      window.history.pushState({}, lection.title, url)
      window.scrollTo(0, 0)

      if (!this.props.preview) {
        client.markLectionStarted(course.id, lection.id, license.id)
          .then(tracker => {
            if (tracker.id) {
              let newTrackers = []
              if (trackers.findIndex(tr => tr.id === tracker.id) !== -1) {
                newTrackers = trackers.map(tr => {
                  if (tr.id === tracker.id) {
                    return tracker
                  } else {
                    return tr
                  }
                })
              } else {
                newTrackers = [...trackers, tracker]
              }

              this.setState({
                trackers: newTrackers,
                progress: this.calculateProgress(newTrackers, course.lections)
              })
            }
          })
      }
    })
  }

  markLectionCompleted = (lection) => {
    if (this.props.preview) { return }

    const trackers = this.state.trackers,
      license = this.props.license

    //return if lection is already completed
    const lectionTracker = trackers.find(tr => tr.lection_id === lection.id)
    if (lectionTracker && lectionTracker.completed_at !== null) {
      return
    }

    client.markLectionCompleted(this.state.course.id, lection.id, license.id)
      .then((tracker) => {
        if (tracker.id) {
          const newTrackers = this.state.trackers.map((tr) => {
            if (tr.id === tracker.id) {
              return tracker
            } else {
              return tr
            }
          })
          this.setState({
            trackers: newTrackers,
            progress: this.calculateProgress(newTrackers, this.state.course.lections)
          })
        }
      })
  }

  showFinishCourseMessage = (event) => {
    const course = this.state.course
    event.preventDefault()
    this.setState({ finishCourseMessageIsActive: true }, () => {
      window.scrollTo(0, 0)
    })
  }

  handleCloseModal = () => {
    $('body').removeClass('shadow')
  }

  renderDeleteConfirmationModal() {
    return (
      <>
        <div className="confirm_modal" id="deleteConfirmationModal">
          <div className="modal_header">
            <div className="container" onClick={this.handleCloseModal}>
              <svg id="close" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                <path d="M14.75 14.75L1.25 1.25" stroke="#666666" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M14.75 1.25L1.25 14.75" stroke="#666666" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            </div>
          </div>
          <div className="container user_form">
            <div className="modal_body d-flex flex-column">
              <h3 className="confirm-title">{window.t("course_viewer.destroy_confirm_title")}</h3>
              <p className="confirm-text">{window.t("course_viewer.destroy_confirm_text")}</p>
              <div className="d-flex justify-content-center">
                <button id="cancel" onClick={this.handleCloseModal} type="button" className="btn btn-pill btn-info my-1 btn-del-room">
                  {window.t('cancel')}
                </button>

                <button id="delete">{window.t('delete')}</button>
              </div>
            </div>
          </div>

        </div>
      </>
    )
  }

  renderLectionContent() {
    const lection = this.state.currentLection
    const videoStartTime = this.state.videoStartTime
    if (lection.content.status == 'transcoding') return

    if (this.state.finishCourseMessageIsActive) {
      return (<FinishCourseMessage course={this.state.course} license={this.props.license} />)
    }

    if (lection.content_id === null && lection.description.length === 0) {
      return (<ContentEmpty />)
    }

    if (lection.content_id === null && lection.description.length > 0) {
      return (<ContentText lection={lection} />)
    }

    if (lection.content.is_video) {
      return (<VideoPlayer
        course={this.props.course}
        license={this.props.license}
        currentLection={this.props.currentLection}
        addNote={this.addNote}
        videoPlayerRef={this.videoPlayerRef}
        lection={lection}
        markLectionCompleted={this.markLectionCompleted}
        ref={this.videoPlayerRef}
        videoStartTime={videoStartTime}
      />)
    } else if (lection.content.is_survey) {
      return (<ContentSurvey
        lection={lection}
        markLectionCompleted={this.markLectionCompleted}
        preview={this.props.prevtab2iew}
        license={this.props.license}
      />)
    } else if (lection.content.is_homework) {
      return (<ContentHomework
        lection={lection}
        markLectionCompleted={this.markLectionCompleted}
        preview={this.props.preview}
      />)
    } else if (lection.content.is_recording) {
      return (<ContentRecording lection={lection} />)
    } else {
      return (<ContentDocument lection={lection} />)
    }
  }

  showMenu(event) {
    let tabs = ['0', '1', '2']
    tabs.splice(tabs.indexOf(event.target.id), 1)
    event.target.classList.add('active')
    document.querySelector(`#tab${event.target.id}`).classList.remove('hide')
    tabs.forEach(function (e) {
      if (document.querySelector(`#tab${e}`)) {
        document.getElementById(e).classList.remove('active')
        document.querySelector(`#tab${e}`).classList.add('hide')
      }
    })
  }

  getLectionByNote(note) {
    return this.props.course.lections.find(l => l.id == note.lection_id)
  }

  showNoteModal(e) {
    e.preventDefault()
    document.getElementById('addNoteModal').classList.add('shadow')
  }

  toggleDescription(event) {
    document.querySelector('.show_more').toggleClass('rotate')
    document.querySelector('#description').toggleClass('hide')
  }


  render() {
    const { course,
      currentLection,
      trackers,
      notes,
      progress,
      finishCourseMessageIsActive } = this.state

    const { locale } = this.props.match.params
    const { license } = this.props

    return (
      <section className="single" style={{ width: '100%' }}>
        {this.state.currentLection.is_video ?
          <>
            <div className="play_top d-flex justify-content-between align-items-center">
              <a href="">{this.state.currentLection.title}</a>
            </div>
            {this.renderLectionContent()}
          </>
          :
          <>
            {this.renderLectionContent()}
          </>
        }
        <div className="wrapper">
          <div className="d-flex flex-column horizantal_left">
            <div className="tab_items d-flex">
              <div id="0" className="active" onClick={this.showMenu}>Описание</div>
              <div id="1" onClick={this.showMenu}>Модули</div>
              {!this.props.preview && <div id="2" onClick={this.showMenu}>Чат курса</div>}
            </div>
            <div className="tab_block">
              <div id="tab0" key="tab0" className="change description" >
                <div className="container story_bottom">
                  <div className="profile_data d-flex align-items-center justify-content-between">
                    <div className="profile_left d-flex align-items-center">
                      <img src={course.author.image_url} alt="" />
                      <div className="d-flex flex-column">
                        <div className="name d-flex align-items-center">
                          {course.author.full_name}
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path d="M21.5599 10.7401L20.1999 9.16006C19.9399 8.86006 19.7299 8.30006 19.7299 7.90006V6.20006C19.7299 5.14006 18.8599 4.27006 17.7999 4.27006H16.0999C15.7099 4.27006 15.1399 4.06006 14.8399 3.80006L13.2599 2.44006C12.5699 1.85006 11.4399 1.85006 10.7399 2.44006L9.16988 3.81006C8.86988 4.06006 8.29988 4.27006 7.90988 4.27006H6.17988C5.11988 4.27006 4.24988 5.14006 4.24988 6.20006V7.91006C4.24988 8.30006 4.03988 8.86006 3.78988 9.16006L2.43988 10.7501C1.85988 11.4401 1.85988 12.5601 2.43988 13.2501L3.78988 14.8401C4.03988 15.1401 4.24988 15.7001 4.24988 16.0901V17.8001C4.24988 18.8601 5.11988 19.7301 6.17988 19.7301H7.90988C8.29988 19.7301 8.86988 19.9401 9.16988 20.2001L10.7499 21.5601C11.4399 22.1501 12.5699 22.1501 13.2699 21.5601L14.8499 20.2001C15.1499 19.9401 15.7099 19.7301 16.1099 19.7301H17.8099C18.8699 19.7301 19.7399 18.8601 19.7399 17.8001V16.1001C19.7399 15.7101 19.9499 15.1401 20.2099 14.8401L21.5699 13.2601C22.1499 12.5701 22.1499 11.4301 21.5599 10.7401ZM16.1599 10.1101L11.3299 14.9401C11.1899 15.0801 10.9999 15.1601 10.7999 15.1601C10.5999 15.1601 10.4099 15.0801 10.2699 14.9401L7.84988 12.5201C7.55988 12.2301 7.55988 11.7501 7.84988 11.4601C8.13988 11.1701 8.61988 11.1701 8.90988 11.4601L10.7999 13.3501L15.0999 9.05006C15.3899 8.76006 15.8699 8.76006 16.1599 9.05006C16.4499 9.34006 16.4499 9.82006 16.1599 10.1101Z" fill="#292D32" />
                          </svg>
                        </div>
                        <div className="email d-flex">
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                            <path d="M6.10671 7.24659C6.04004 7.23992 5.96004 7.23992 5.88671 7.24659C4.30004 7.19325 3.04004 5.89325 3.04004 4.29325C3.04004 2.65992 4.36004 1.33325 6.00004 1.33325C7.63337 1.33325 8.96004 2.65992 8.96004 4.29325C8.95337 5.89325 7.69337 7.19325 6.10671 7.24659Z" stroke="#888888" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M10.9399 2.66675C12.2333 2.66675 13.2733 3.71341 13.2733 5.00008C13.2733 6.26008 12.2733 7.28675 11.0266 7.33341C10.9733 7.32675 10.9133 7.32675 10.8533 7.33341" stroke="#888888" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M2.77335 9.70675C1.16002 10.7867 1.16002 12.5467 2.77335 13.6201C4.60669 14.8467 7.61336 14.8467 9.44669 13.6201C11.06 12.5401 11.06 10.7801 9.44669 9.70675C7.62002 8.48675 4.61335 8.48675 2.77335 9.70675Z" stroke="#888888" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M12.2268 13.3333C12.7068 13.2333 13.1601 13.0399 13.5335 12.7533C14.5735 11.9733 14.5735 10.6866 13.5335 9.90659C13.1668 9.62659 12.7201 9.43992 12.2468 9.33325" stroke="#888888" strokeLinecap="round" strokeLinejoin="round" />
                          </svg>
                          {course.author.customers_count} учеников
                        </div>
                      </div>
                    </div>
                    <a href={Routes.market_tutor_path(course.author.subdomain)} className="profile_btn">Перейти</a>
                  </div>

                  <div id="description" className="story_desc hide">
                    <p className="story_message" dangerouslySetInnerHTML={{ __html: course.description }}></p>
                  </div>
                  {course.description && course.description.length > 200 ?
                    <div className="show_more d-flex align-items-center desc_none" onClick={event => this.toggleDescription(event)}>
                      Читать полностью
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                        <path d="M13.2802 5.96658L8.93355 10.3132C8.42021 10.8266 7.58022 10.8266 7.06688 10.3132L2.72021 5.96658" stroke="#329A80" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                      </svg>
                    </div>
                    : ''
                  }
                  {notes.length != 0 ?
                    <>
                      <div className="block_title">Заметки:</div>
                      {notes.map((note) => (
                        <div className="action d-flex justify-content-between align-items-center" key={note.id}>
                          <div className="action_left d-flex align-items-center">
                            <a href="#" onClick={event => this.loadLection(event, this.getLectionByNote(note), note.time_seconds)}>
                              Перейти
                            </a>
                            <div className="action_text">{note.title}<span>({new Date(note.time_seconds * 1000).toISOString().slice(11, 19)})</span></div>
                          </div>
                          <a href="#" onClick={(event) => this.showDeleteNoteConfirmation(event, note)}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                              <path d="M21 6.47998C17.67 6.14998 14.32 5.97998 10.98 5.97998C9 5.97998 7.02 6.07998 5.04 6.27998L3 6.47998" stroke="#888888" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                              <path d="M8.5 5.47L8.72 4.16C8.88 3.21 9 2.5 10.69 2.5H13.31C15 2.5 15.13 3.25 15.28 4.17L15.5 5.47" stroke="#888888" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                              <path d="M18.85 9.63989L18.2 19.7099C18.09 21.2799 18 22.4999 15.21 22.4999H8.79002C6.00002 22.4999 5.91002 21.2799 5.80002 19.7099L5.15002 9.63989" stroke="#888888" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                              <path d="M10.33 17H13.66" stroke="#888888" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                              <path d="M9.5 13H14.5" stroke="#888888" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                          </a>
                        </div>
                      ))
                      }
                    </>
                    : ''
                  }

                </div>

              </div>
              <div id="tab1" key="tab1" className="change lessons_block d-flex flex-column hide">

                <div className="lessons" id="accordionSections">
                  {course.sections.map((section, index) => (
                    <CourseSection
                      key={'section' + section.id}
                      section={section}
                      course={course}
                      index={index}
                      locale={locale}
                      currentLection={currentLection}
                      loadLection={this.loadLection}
                      trackers={trackers}
                      notes={notes}
                      isOpened={section.lections.findIndex(l => l.id === currentLection.id) !== -1}
                      showFinishCourseLink={index === course.sections.length - 1 && progress === 100}
                      showFinishCourseMessage={this.showFinishCourseMessage}
                      finishCourseMessageIsActive={finishCourseMessageIsActive}
                      deleteNote={this.deleteNote}
                    />
                  ))}
                </div>
              </div>
              {!this.props.preview &&
                <div id="tab2" key="tab2" className="change chat player-chat player-chat-bk hide" style={{ width: '200%', marginBottom: '100px' }} >

                  <ConversationMobile
                    conversation={this.state.conversation}
                    canUpdateConversation={'canUpdateConversation'}
                    currentUserId={license.user.id}
                  />
                </div>}
            </div>
          </div>
        </div>
        {this.renderDeleteConfirmationModal()}
      </section>
    )
  }
}

export default CourseViewerMobile
